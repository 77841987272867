import React from "react";
import { Node } from "interweave";

export const gatsbyLinkTransform = (node: HTMLElement, children: Node[]): React.ReactNode => {
    if (node.tagName.toLowerCase() === 'a' && node.getAttribute('href')) {
        const destination = node.getAttribute('href') as string;

        return <a href={destination}>{children}</a>;
    }
    if (node.tagName === 'span') {
        return <span>{children}</span>
    }
}