import Interweave, { Markup } from "interweave";
import { EmailMatcher, UrlMatcher } from 'interweave-autolink';
import React from "react";
import { gatsbyLinkTransform } from '../../utils/interweave-utils';
import LocalizedLink from "../LocalizedLink";
import styled from 'styled-components';
import { LocalizedLinkProps } from '../LocalizedLink';

//#region AdmissionOpenHeading
export const AdmissionOpenHeading = styled.h1`
  font-size: 3rem;
  text-align: center;
  color: #bebebe;
  padding-bottom: 1rem;
  letter-spacing: 2px;
  @media(max-width: 570px) {
    font-size: 2rem;
  }
`;
//#endregion

//#region AdmissionOpenBody
export const AdmissionOpenBody = styled.h1`
  font-size: 1.4rem;
  text-align: center;
  color: #bebebe;
  font-family: "Fredericka the Great";
  letter-spacing: 2px;
  padding-bottom: .5rem;
  line-height: 2rem;
  position: relative;
  @media(max-width: 570px) {
    font-size: 1rem;
  }
`;
//#endregion

//#region AdmissionOpenBody
export const AboutUsBody = styled(AdmissionOpenBody)`
  font-family: "Roboto";
  padding : 0 1rem;
  color: whitesmoke;
  
  @media(min-width: 1400px) {
    width: 75%;
    align-self: center;
  }
`;
//#endregion

//#region AdmissionOpenHeading
export const AdmissionOpenHeadingTop = styled.h1`
  font-size: 5rem;
  text-align: center;
  letter-spacing: 2px;
  color: #bebebe;
  padding-bottom: 1.5rem;
  @media(max-width: 570px) {
    font-size: 3rem;
  }
`;
//#endregion

//#region SubTitle
export const SubTitle = styled.h1`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.3em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #dddddd;
    padding-top: 1rem;
    @media (max-width: 550px) {
        font-size: 1.15em;
    }
`;
//#endregion

//#region PageText

const StyledParagraph = styled(Interweave)`
    font-weight: 400;
    font-size: 1.2em;
    text-align: start;
    margin: 2rem 0;
    color: whitesmoke;
    text-align: center;
    font-family: "play";
    line-height: 2rem;
    letter-spacing: 2px;

    @media (max-width: 1300px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

interface PageTextProps {
    children?: string;
    tagName?: string;
}

export const PageText: React.FunctionComponent<PageTextProps> = ({ children, tagName = "p" }) => {
    return <StyledParagraph
        tagName={tagName}
        matchers={[new EmailMatcher('email'), new UrlMatcher('url')]}
        transform={gatsbyLinkTransform}
        content={children}
    />;
};
//#endregion

//#region Title
const TitleText = styled.h1`
    display: flex;
    place-content: center;
    align-items: center;
    line-height: 2.7rem;
    text-align: center;
    font-size: 2.2em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #f8f8f8;
    max-width: 40rem;
    margin: 1rem auto 0.5rem auto;
    @media (max-width: 550px) {
        font-size: 2em;
    }
`;

export const Title: React.FC = ({ children }) => {
    if (!children) {
        return null;
    }
    return <TitleText>{children}</TitleText>;
};
//#endregion